<template>
	<div class="footer-wrapper">
		<p class="footerwrapper-title"></p>
	</div>
</template>

<script type="text/ecmascript-6">
export default {
	data() {
		return {};
	},
	created() {},
	methods: {}
};
</script>

<style lang="scss">
  @import "../../common/scss/base";
  .footer-wrapper {
    width: 100%;
    height: 24px;
    text-align: center;
    background-color: transparent;
  }
  .footerwrapper-title {
    font-size: 14px;
    color: #9B9B9B;
    line-height: 24px;
  }

</style>
